import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import {
  ChannelName,
  downloadWindDataExcel,
  getWindDirection,
  windSpeedValueConversion,
} from '@awareness/util';
import { Button } from '../Button';

interface WindEChartI {
  data: any;
  name: string;
  channelName: ChannelName;
  minMax: {
    startDate: Date;
    endDate: Date;
  };
  unit: string;
}

const WindEChart: React.FC<WindEChartI> = ({
  data,
  name,
  minMax,
  channelName,
  unit,
}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartDom = chartRef.current;
    const myChart = echarts.init(chartDom);

    const parsedData = data.map((d: any) => {
      const parsed = d.value ? JSON.parse(d.value) : [];
      return {
        time: d.timestamp,
        windSpeed:
          parsed.length > 1
            ? windSpeedValueConversion(
                //@ts-ignore
                Number(parsed[0]),
                unit,
                //@ts-ignore
                'mph' // default unit for wind
              )
            : 0,
        R: getWindDirection(parsed[1]),
      };
    });
    const directionMap: any = {};
    [
      'W',
      'WSW',
      'SW',
      'SSW',
      'S',
      'SSE',
      'SE',
      'ESE',
      'E',
      'ENE',
      'NE',
      'NNE',
      'N',
      'NNW',
      'NW',
      'WNW',
    ].forEach((name, index) => {
      directionMap[name] = (Math.PI / 8) * index;
    });
    const dataNew: any = parsedData.map((entry: any) => [
      entry.time,
      entry.windSpeed,
      entry.R,
      entry.waveHeight,
    ]);
    const dims = {
      time: 0,
      windSpeed: 1,
      R: 2,
      waveHeight: 3,
      weatherIcon: 2,
      minTemp: 3,
      maxTemp: 4,
    };
    const arrowSize = 18;

    const renderArrow = (param: any, api: any) => {
      const point = api.coord([
        api.value(dims.time),
        api.value(dims.windSpeed),
      ]);
      return {
        type: 'path',
        shape: {
          pathData: 'M31 16l-15-15v9h-26v12h26v9z',
          x: -arrowSize / 2,
          y: -arrowSize / 2,
          width: arrowSize,
          height: arrowSize,
        },
        rotation: directionMap[api.value(dims.R)],
        position: point,
        style: api.style({
          stroke: '#555',
          lineWidth: 1,
        }),
      };
    };

    const option = {
      title: {
        text: 'Wind Speed Data',
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => {
          const formattedDate = params[0].value[dims.time] + 'Z';
          return [
            echarts.format.formatTime(
              'yyyy-MM-dd',
              params[0].value[dims.time]
            ) +
              ' ' +
              echarts.format.formatTime('hh:mm', formattedDate),
            'Wind speed: ' + Number(params[0].value[dims.windSpeed]).toFixed(2),
            'Wind Direction:' + params[0].value[dims.R],
          ].join('<br>');
        },
      },
      grid: {
        top: 160,
        bottom: 125,
      },
      xAxis: {
        type: 'time',
        maxInterval: 3600 * 1000 * 24,
        splitLine: {
          lineStyle: {
            color: '#ddd',
          },
        },
      },
      yAxis: [
        {
          name,
          nameLocation: 'middle',
          nameGap: 35,
          axisLine: {
            lineStyle: {
              color: '#666',
            },
          },
          splitLine: {
            lineStyle: {
              color: '#ddd',
            },
          },
        },

        {
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false },
          splitLine: { show: false },
        },
      ],
      visualMap: {
        type: 'piecewise',
        orient: 'horizontal',
        left: 'center',
        bottom: 10,
        pieces: [
          {
            gte: 25,
            color: '#D33C3E',
            label: 'Strong Wind（greater than 25 mph)',
          },
          {
            gte: 13,
            lt: 24,
            color: '#f4e9a3',
            label: 'Stroke (range 13 to 24 mph)',
          },
          {
            lt: 12,
            color: '#18BF12',
            label: 'Breeze (less than 12 mph)',
          },
        ],
        seriesIndex: 1,
        dimension: 1,
      },
      dataZoom: [
        {
          type: 'inside',
          xAxisIndex: 0,
          minSpan: 5,
        },
        {
          type: 'slider',
          xAxisIndex: 0,
          minSpan: 5,
          bottom: 50,
        },
      ],
      series: [
        {
          type: 'line',
          yAxisIndex: 1,
          showSymbol: false,
          emphasis: {
            scale: false,
          },
          symbolSize: 10,
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              global: false,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(88,160,253,1)',
                },
                {
                  offset: 0.5,
                  color: 'rgba(88,160,253,0.7)',
                },
                {
                  offset: 1,
                  color: 'rgba(88,160,253,0)',
                },
              ],
            },
          },
          lineStyle: {
            color: 'rgba(88,160,253,1)',
          },
          itemStyle: {
            color: 'rgba(88,160,253,1)',
          },
          encode: {
            x: dims.time,
            y: dims.waveHeight,
          },
          data: dataNew,
          z: 2,
        },
        {
          type: 'custom',
          renderItem: renderArrow,
          encode: {
            x: dims.time,
            y: dims.windSpeed,
          },
          data: dataNew,
          z: 10,
        },
        {
          type: 'line',
          symbol: 'none',
          encode: {
            x: dims.time,
            y: dims.windSpeed,
          },
          lineStyle: {
            color: '#aaa',
            type: 'dotted',
          },
          data: dataNew,
          z: 1,
        },
        // {
        //   type: "custom",
        //   renderItem: renderWeather,
        //   data: weatherData,
        //   tooltip: {
        //     trigger: "item",
        //     formatter: function (param) {
        //       return (
        //         param.value[dims.time] +
        //         ": " +
        //         param.value[dims.minTemp] +
        //         " - " +
        //         param.value[dims.maxTemp] +
        //         "°"
        //       );
        //     },
        //   },
        //   yAxisIndex: 2,
        //   z: 11,
        // },
      ],
    };
    myChart.setOption(option);

    return () => {
      // Clean up when unmounting
      myChart.dispose();
    };
  }, []);

  return (
    <>
      <div ref={chartRef} style={{ width: '100%', height: '500px' }} />;
      <Button
        label="Download Excel"
        onClick={() => {
          downloadWindDataExcel(data, unit, channelName);
        }}>
        Download Excel
      </Button>
    </>
  );
};

export default WindEChart;

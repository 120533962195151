import React, { useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Charts from 'react-apexcharts';
import { getChannelUnit, updateInitialState } from '@awareness/channel';

import { theme } from '@awareness-ui/design';
import {
  H1,
  Column,
  Subtext,
  Button,
  ColorDot,
  TextDownloader,
} from '@awareness-ui/components';
import { Asset, ChannelList, ChannelMeasurement } from '@awareness/types';
import {
  CHANNEL_WIND,
  ChannelName,
  formatDate,
  timeSeriesToCSV,
} from '@awareness/util';
import { getApiEndpoint } from '@awareness/api-endpoint';
import { getToken } from '@awareness/auth';
import { apiFetchAsync } from '@awareness/api-fetch';
import { formatUnitString, metricToImperial } from '@awareness/assets';
import ReactApexChart from 'react-apexcharts';
import { getUser } from '@awareness/user';
import { TimeSeries } from 'pondjs';
import {
  barometricPressureValueConversion,
  windSpeedValueConversion,
  rainDiffValueConversion,
  airTemperatureValueConversion,
  CHANNEL_AIR_TEMPRATURE,
  CHANNEL_DEW_POINT,
  CHANNEL_RAIN_DIFF,
  CHANNEL_DELTA_RAIN,
  CHANNEL_WIND_SPEED,
  CHANNEL_BAROMETRIC_PRESSURE,
  CHANNEL_TOTAL_RAIN,
} from '@awareness/util';
import { store } from 'src/store';

const MainView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
`;

const Header = styled.div`
  width: 100%;
  height: 150px;
  padding: 55px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.color.border.medium};
`;

const Content = styled.div`
  padding: 55px;
`;

const Loading = styled.div`
  margin-left: 55px;
`;

const Graph = styled.div`
  padding: 0 55px;
`;

const AssetList = styled.div`
  margin-left: 55px;
  display: flex;
`;

const AssetColumn = styled.div`
  margin-right: 40px;
`;

const AssetListItem = styled.div`
  display: flex;
  margin-top: 16px;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
`;

type ChannelDataObject = { [key: string]: ChannelMeasurement[] | undefined };

function getTimeSeries(data: ChannelDataObject) {
  const ids = Object.keys(data);
  const points = ids
    .reduce((acc, id, index) => {
      const toAdd = (data[id] || []).map((d) => {
        const timestamp = new Date(d.timestamp).valueOf();
        const column = [timestamp];
        column[index + 1] = parseFloat(d.value);
        return column;
      });
      return [...acc, ...toAdd];
    }, [] as number[][])
    .sort((a, b) => a[0] - b[0]);

  const columns = ['time', ...ids];

  return new TimeSeries({
    name: 'data',
    columns,
    points: points,
  });
}
interface Props extends RouteChildrenProps {}

export const ReportView: React.FC<Props> = (props) => {
  const channelsStr: string = (props.match?.params || ({} as any)).channel;
  const idsStr: string = (props.match?.params || ({} as any)).primaries;
  const secondaryIdStr: string = (props.match?.params || ({} as any)).secondary;
  const dates: string = (props.match?.params || ({} as any)).dates;
  const [startDateStr, endDateStr] = dates.split(',');
  const startDate = new Date(parseInt(startDateStr));
  const endDate = new Date(parseInt(endDateStr));

  const [assets, setAssets] = useState<{ [key: number]: Asset | undefined }>(
    {}
  );
  const [primaryChannel, setPrimaryChannel] = useState<ChannelName>();
  const [unitPrimary, setUnitPrimary] = useState<string>('');
  const [ids, setIds] = useState<Array<string>>([]);
  const [timeSeries, setTimeSeries] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [primaryCSV, setPrimaryCSV] = useState<string>('');

  const [secondaryChannel, setSecondaryChannel] = useState<ChannelName>();
  const [unitSecondary, setUnitSecondary] = useState<string>('');
  const [secondaryId, setSecondaryId] = useState<string>();
  const [secondaryTimeSeries, setSecondaryTimeSeries] = useState<any>();
  const [secondaryAsset, setSecondaryAsset] = useState<Asset>();
  const [secondaryLoading, setSecondaryLoading] = useState<boolean>(true);

  const apiEndpoint = useSelector(getApiEndpoint);
  const token = useSelector(getToken);
  const user = useSelector(getUser);
  const channelData: any = useSelector(getChannelUnit);

  useEffect(() => {
    const channels = channelsStr.split(',') as ChannelName[];
    const primary = channels[0];
    const secondary = channels[1];
    setPrimaryChannel(primary);
    if (secondary) setSecondaryChannel(secondary);
    setIds(idsStr.split(','));
    if (secondaryIdStr) setSecondaryId(secondaryIdStr);
  }, []);

  useEffect(() => {
    fetchData();
    fetchChannelUnit();
  }, [primaryChannel, unitPrimary]);

  useEffect(() => {
    if (secondaryChannel) fetchSecondaryData();
  }, [secondaryChannel, unitSecondary]);

  async function fetchChannelUnit() {
    if (!ids?.length) {
      return;
    }

    const url = `${apiEndpoint}/channels`;
    try {
      const response = await apiFetchAsync({ url, token });
      if (response.status < 200 || response.status >= 300) {
        return;
      }
      const json: ChannelList = await response.json();
      const convertedJson = json.reduce(
        (memo: { [key: string]: string }, cd) => {
          memo[cd.name] = cd.unit;
          return memo;
        },
        {}
      );
      store.dispatch(updateInitialState(convertedJson));

      const primaryUnit = json.find((c) => c.name === primaryChannel)?.unit;
      const secondaryUnit = json.find((c) => c.name === secondaryChannel)?.unit;

      if (primaryUnit) {
        setUnitPrimary(formatUnitString(primaryUnit) || '');
      }
      if (secondaryUnit) {
        setUnitSecondary(formatUnitString(secondaryUnit) || '');
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchData() {
    if (!primaryChannel) return;
    const viewData = await Promise.all(ids.map((id) => fetchAsset(id)));
    const channelDatas = await Promise.all(
      ids.map((id) => fetchChartData(id, primaryChannel))
    );
    const newAssets: any = viewData.reduce(
      (acc, r) => (r ? { ...acc, [r.id]: r } : acc),
      {}
    );

    const newChannelData: any = channelDatas.reduce(
      (acc, r, i) => (r ? { ...acc, [ids[i]]: r } : acc),
      {}
    );
    setAssets(newAssets);
    const columns = Object.keys(newChannelData);
    const ts = getTimeSeries(newChannelData)
      .fill({ fieldSpec: columns, method: 'linear', limit: 180 })
      .align({ fieldSpec: columns, period: '5m', method: 'linear', limit: 60 });

    const points = columns.map((columnsKey: any) => {
      const channelDataFromKey = newChannelData[columnsKey];
      const assetName = newAssets[columnsKey]?.name;
      return {
        assetName,
        points: channelDataFromKey.map((d: any) => {
          // manula conversion of wind datafrom mps to mph
          let value =
            primaryChannel === CHANNEL_WIND
              ? windSpeedValueConversion(
                  //@ts-ignore
                  parseFloat(d.value),
                  unitSecondary,
                  //@ts-ignore
                  channelData[primaryChannel]
                )
              : parseFloat(d.value);
          if (!user.client?.metric_units) {
            value = metricToImperial(value, unitPrimary, true).value;
          }
          return [new Date(d.timestamp).valueOf(), value];
        }),
      };
    });
    setTimeSeries(points);
    setLoading(false);
    //@ts-ignore
    setPrimaryCSV(
      timeSeriesToCSV(
        ts.toJSON(),
        channelData[primaryChannel] && channelData[primaryChannel] !== ''
          ? channelData[primaryChannel]
          : unitPrimary
      )
    );
  }

  async function fetchSecondaryData() {
    if (!secondaryChannel || !secondaryId) return;
    setSecondaryAsset((await fetchAsset(secondaryId)) as Asset);
    const newChannelData = await Promise.all(
      ids.map((id) => fetchChartData(id, secondaryChannel))
    );
    const points = newChannelData[0]?.map((d: any) => {
      // manual conversion of wind data
      let value =
        secondaryChannel === CHANNEL_WIND
          ? windSpeedValueConversion(
              //@ts-ignore
              parseFloat(d.value),
              unitSecondary,
              //@ts-ignore
              channelData[secondaryChannel] &&
                channelData[secondaryChannel] !== ''
                ? channelData[secondaryChannel]
                : unitSecondary
            )
          : parseFloat(d.value);
      if (!user.client?.metric_units) {
        value = metricToImperial(value, unitSecondary, true).value;
      }
      return [new Date(d.timestamp).valueOf(), value];
    });
    setSecondaryTimeSeries(points);
    setSecondaryLoading(false);
  }

  async function fetchAsset(id: any) {
    const url = `${apiEndpoint}/assets/${id}`;
    try {
      const response = await apiFetchAsync({ url, token });
      if (response.status < 200 || response.status >= 300) {
        return;
      }
      const json: Asset = await response.json();
      return json;
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchChartData(id: string, channel: ChannelName) {
    const preprocessData = (data: any) => {
      return data.map((d: any) => {
        if (
          channel === CHANNEL_AIR_TEMPRATURE ||
          channel === CHANNEL_DEW_POINT
        ) {
          return {
            ...d,
            value: airTemperatureValueConversion(
              //@ts-ignore
              Number(d.value),
              undefined,
              //@ts-ignore
              channelData[channel]
            ).toFixed(2),
          };
        } else if (
          channel === CHANNEL_DELTA_RAIN ||
          channel === CHANNEL_TOTAL_RAIN ||
          channel === CHANNEL_RAIN_DIFF
        ) {
          return {
            ...d,
            value: rainDiffValueConversion(
              //@ts-ignore
              Number(d.value),
              undefined,
              //@ts-ignore
              channelData[channel]
            ).toFixed(2),
          };
        } else if (channel === CHANNEL_BAROMETRIC_PRESSURE) {
          return {
            ...d,
            value: barometricPressureValueConversion(
              //@ts-ignore
              Number(d.value),
              undefined,
              //@ts-ignore
              channelData[channel]
            ).toFixed(2),
          };
          return d;
        } else if (channel === CHANNEL_WIND_SPEED) {
          return {
            ...d,
            value: windSpeedValueConversion(
              //@ts-ignore
              Number(d.value),
              undefined,
              //@ts-ignore
              channelData[name]
            ).toFixed(2),
          };
        } else if (channel === CHANNEL_WIND) {
          const windSpedValue = JSON.parse(d.value);
          return {
            ...d,
            value: Number(windSpedValue[0]).toFixed(2),
          };
        } else {
          return {
            ...d,
            value: Number(d.value).toFixed(2),
          };
        }
      });
    };

    endDate.setHours(endDate.getHours() + 24);
    const url = `${apiEndpoint}/assets/${id}/channels/${channel}/data?start=${startDate.toISOString()}&end=${endDate.toISOString()}`;

    try {
      const response = await apiFetchAsync({ url, token });
      if (response.status < 200 || response.status >= 300) {
        return;
      }
      const json: ChannelMeasurement[] = await response.json();
      const convertedJson = preprocessData(json);

      return convertedJson.reverse().map(({ value, timestamp }: any) => {
        return {
          value,
          timestamp: timestamp?.includes('Z') ? timestamp : timestamp + 'Z',
        };
      });
    } catch (err) {
      console.log(err);
    }
  }

  const colors = [
    ...ids.map((id, index) => {
      const a = assets[id as any];
      if (a) {
        return theme.color.graph[index];
      }
    }),
    '#808080',
  ];

  const series = [
    ...(timeSeries || []).map((ts: any) => ({
      name: `${primaryChannel} (${ts.assetName})`,
      type: 'area',
      data: ts.points,
    })),
    {
      name: secondaryChannel,
      type: 'area',
      data: secondaryTimeSeries,
    },
  ];
  const options = {
    chart: {
      id: secondaryChannel
        ? `${primaryChannel} vs ${secondaryChannel}`
        : primaryChannel,
      height: 350,
      zoom: {
        autoScaleYaxis: true,
      },
      toolbar: {
        export: {
          csv: {
            headerCategory: 'Date',
            columnDelimiter: ';',
            dateFormatter: function (timestamp: any) {
              const date = new Date(timestamp);
              const now = new Date();
              // Display time only if data points have the same year and month
              const formattedTime = date.toLocaleTimeString([], {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              });
              return formattedTime;
            },
          },
        },
      },
    },
    annotations: {
      yaxis: [
        {
          title: {
            text: `${primaryChannel} (${unitPrimary})`,
          },
        },
      ],
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
      // style: 'hollow',
    },
    yaxis: [
      {
        title: {
          text: primaryChannel
            ? primaryChannel +
              '(' +
              (channelData[primaryChannel] && channelData[primaryChannel] !== ''
                ? channelData[primaryChannel]
                : unitPrimary) +
              ')'
            : '',
        },
      },
    ],
    xaxis: {
      type: 'datetime',
      min: startDate.toLocaleString(),
      max: endDate.toLocaleString(),
      tickAmount: 6,
      labels: {
        formatter: function (value: number, timestamp: number, opts: any) {
          const date = new Date(timestamp);
          const now = new Date();

          if (
            date.getFullYear() === now.getFullYear() &&
            date.getMonth() === now.getMonth()
          ) {
            // Display time only if data points have the same year and month
            const formattedTime = date.toLocaleTimeString([], {
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            });
            return formattedTime;
          } else {
            const formattedDate = date.toLocaleString(undefined, {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            });
            return formattedDate;
          }
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
  };

  if (secondaryChannel) {
    options.yaxis.push({
      opposite: true,
      title: {
        text: `${secondaryChannel} (${
          channelData[secondaryChannel] && channelData[secondaryChannel] !== ''
            ? channelData[secondaryChannel]
            : unitSecondary
        })`,
      },
    } as any);
  }

  if (!primaryChannel)
    return <MainView>There was no primary data channel selected!</MainView>;
  return (
    <MainView>
      <Header>
        <Column>
          <H1>
            {primaryChannel}
            {secondaryChannel && ` vs. ${secondaryChannel}`} Report
          </H1>
          {formatDate(startDate)} - {formatDate(endDate)}
        </Column>
        <Row>
          <Button
            label="Back to Reports"
            to="/reports"
            appearance="secondary"
          />
          <Button
            label="Print"
            style={{ marginLeft: 10 }}
            onClick={() => window.print()}
          />
        </Row>
      </Header>
      <Content>
        {loading || (secondaryChannel && secondaryLoading) ? (
          <Loading>Loading...</Loading>
        ) : (
          <>
            <Charts
              options={options}
              series={series || []}
              type="area"
              height={400}
              width="650"
            />
            {primaryCSV && (
              <TextDownloader title={primaryChannel} data={primaryCSV} />
            )}
            <AssetList>
              <AssetColumn>
                <Subtext>
                  Assets ({Object.values(assets)?.length}), {primaryChannel}
                </Subtext>
                {ids.map((id, index) => {
                  const a = assets[id as any];
                  return (
                    a && (
                      <AssetListItem key={a.id}>
                        <ColorDot color={theme.color.graph[index]} />
                        {a.name}
                      </AssetListItem>
                    )
                  );
                })}
              </AssetColumn>

              {secondaryAsset && (
                <AssetColumn>
                  <Subtext>
                    Secondary Asset, {secondaryChannel}
                    <br />
                  </Subtext>
                  <AssetListItem>
                    <ColorDot color="grey" />
                    {secondaryAsset.name}
                  </AssetListItem>
                </AssetColumn>
              )}
            </AssetList>
          </>
        )}
      </Content>
    </MainView>
  );
};

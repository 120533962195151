import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { TimeRange } from 'pondjs';
import { DateRangePicker } from 'react-date-range';

import { formatChannelName, theme, toRem } from '@awareness-ui/design';
import { ChannelMeasurement } from '@awareness/types';
import { getApiEndpoint } from '@awareness/api-endpoint';
import { getToken } from '@awareness/auth';
import { ModalContent } from '../Modal';
import { Icon } from '../Icon';
import { H2, Label } from '../Typography';
import { Button } from '../Button';
import { apiFetchAsync } from '@awareness/api-fetch';
import toast from 'react-hot-toast';
import { getUser } from '@awareness/user';
import { metricToImperial } from '@awareness/assets';
import LineChart from './LineChart';
import {
  formatDate,
  barometricPressureValueConversion,
  windSpeedValueConversion,
  rainDiffValueConversion,
  airTemperatureValueConversion,
  MEASUREMENT_ICON_NAMES,
  CHANNEL_AIR_TEMPRATURE,
  CHANNEL_DEW_POINT,
  CHANNEL_RAIN_DIFF,
  CHANNEL_DELTA_RAIN,
  CHANNEL_WIND_SPEED,
  CHANNEL_BAROMETRIC_PRESSURE,
  CHANNEL_TOTAL_RAIN,
  RAIN_UNIT_OPTIONS,
  CHANNEL_WIND,
  getWindDirection,
} from '@awareness/util';
import { getChannelUnit } from '@awareness/channel';
import WindEChart from './WindEChart';
import LineEChart from './LineEChart';

const Layout = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
`;

const DatePickerContainer = styled.div`
  position: absolute;
  z-index: 1;
  right: 0;
  box-shadow: ${theme.shadow.card};
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChartHeader = styled.header`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: ${toRem(60)};
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${toRem(8)};
`;

const ChartTitle = styled(H2)`
  font-family: ${theme.font.family.inter};
  font-weight: ${theme.font.weight.semiBold};
  padding-left: ${toRem(8)};
`;

const SubHeader = styled(Label)`
  color: ${theme.color.text[66]};
`;

const ErrorLabel = styled(Label)`
  color: ${theme.color.error};
`;

export const dataHasGap = (d1: Date, d2: Date) =>
  Math.abs(d1.valueOf() - d2.valueOf()) >= DAY_MILLISECONDS;

const DAY_MILLISECONDS = 24 * 60 * 60 * 1000;

const NOW = new Date();

interface Props extends ChannelMeasurement {
  id: number | null;
}

export const Chart: React.FC<Props> = ({ name, unit, value, id }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>();
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(
    new Date(endDate.valueOf() - DAY_MILLISECONDS * 7)
  );
  const [timeRange, setTimeRange] = useState(new TimeRange(startDate, endDate));
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [dataMissingError, setDataMissingError] = useState(false);

  const apiEndpoint = useSelector(getApiEndpoint);
  const token = useSelector(getToken);
  const user = useSelector(getUser);
  const channelData = useSelector(getChannelUnit);

  const preprocessData = (data: any) => {
    return data.map((d: any) => {
      if (name === CHANNEL_AIR_TEMPRATURE || name === CHANNEL_DEW_POINT) {
        return {
          ...d,
          value: airTemperatureValueConversion(
            //@ts-ignore
            Number(d.value),
            undefined,
            //@ts-ignore
            channelData[name]
          ).toFixed(2),
        };
      } else if (
        name === CHANNEL_DELTA_RAIN ||
        name === CHANNEL_TOTAL_RAIN ||
        name === CHANNEL_RAIN_DIFF
      ) {
        return {
          ...d,
          value: rainDiffValueConversion(
            //@ts-ignore
            Number(d.value),
            undefined,
            //@ts-ignore
            channelData[name]
          ).toFixed(2),
        };
      } else if (name === CHANNEL_BAROMETRIC_PRESSURE) {
        return {
          ...d,
          value: barometricPressureValueConversion(
            //@ts-ignore
            Number(d.value),
            undefined,
            //@ts-ignore
            channelData[name]
          ).toFixed(2),
        };
        return d;
      } else if (name === CHANNEL_WIND_SPEED) {
        return {
          ...d,
          value: windSpeedValueConversion(
            //@ts-ignore
            Number(d.value),
            undefined,
            //@ts-ignore
            channelData[name]
          ).toFixed(2),
        };
      } else {
        return {
          ...d,
          value: Number(d.value).toFixed(2),
        };
      }
    });
  };

  const fetchChartData = async () => {
    setLoading(true);
    setTimeRange(new TimeRange(startDate, endDate));
    endDate.setHours(endDate.getHours() + 24);
    const url = `${apiEndpoint}/assets/${id}/channels/${name}/data?start=${startDate.toISOString()}&end=${endDate.toISOString()}`;
    try {
      const response = await apiFetchAsync({ url, token });
      if (response.status < 200 || response.status >= 300) {
        throw new Error('There was an error fetching the chart data.');
      }
      const json: ChannelMeasurement[] = await response.json();

      const convertedJson = preprocessData(json);
      if (!convertedJson || !convertedJson[0]) {
        throw new Error('No data in the selected date range');
      }
      if (name === CHANNEL_WIND) {
        setData(json);
      } else {
        const points = convertedJson.reverse().map((d: any) => {
          let value = `${parseFloat(d.value)}`;
          // if (!user.client?.metric_units) {
          //   value = metricToImperial(value, unit, true).value;
          // }
          const updatedtimistampValue = !d.timestamp?.includes('Z')
            ? d.timestamp + 'Z'
            : d.timestamp;
          // return [new Date(updatedtimistampValue).valueOf(), value];
          return [updatedtimistampValue, value];
        });
        setData(points);
      }
      setLoading(false);
    } catch (err) {
      toast.error(`${err}`);
      setLoading(false);
      setDataMissingError(true);
    }
  };

  useEffect(() => {
    if (id && name) {
      fetchChartData();
    }
  }, [name, id, apiEndpoint, token]);

  const onDatePickerChange = (range: {
    selection: { startDate: Date; endDate: Date };
  }) => {
    setStartDate(range.selection.startDate);
    setEndDate(range.selection.endDate);
  };

  const closeDatePicker = () => {
    setDatePickerOpen(false);
    if (
      startDate.valueOf() !== timeRange.begin().valueOf() ||
      endDate.valueOf() !== timeRange.end().valueOf()
    ) {
      fetchChartData();
    }
  };

  const valueToConsider = name === 'Wind' ? JSON.parse(value)[0] ?? 0 : value;
  const additionalInfo =
    name.toLowerCase() === CHANNEL_WIND.toLowerCase()
      ? getWindDirection(JSON.parse(value)[1] ?? 0)
      : '';
  const finalUnit =
    name.toLowerCase() === CHANNEL_WIND.toLowerCase() ? 'mph' : unit;
  return (
    <Layout style={{ width: '100%' }}>
      {loading ? (
        <ChartTitle>Loading...</ChartTitle>
      ) : (
        <>
          <ChartHeader>
            <Left>
              <HeaderTitleContainer>
                <Icon
                  icon={MEASUREMENT_ICON_NAMES[name]}
                  width={16}
                  height={16}
                />
                <ChartTitle>{formatChannelName(name)}</ChartTitle>
              </HeaderTitleContainer>
              <SubHeader>
                Current Level: {value ? Number(valueToConsider).toFixed(2) : ''}{' '}
                {finalUnit} {additionalInfo}
              </SubHeader>
            </Left>
            <div>
              <Button
                size="small"
                label={`Choose Date Range  ${datePickerOpen ? '▼' : '▲'}`}
                onClick={
                  datePickerOpen
                    ? closeDatePicker
                    : () => setDatePickerOpen(!datePickerOpen)
                }
              />
              <SubHeader>
                {formatDate(startDate)} - {formatDate(endDate)}
                {dataMissingError ? <ErrorLabel>*</ErrorLabel> : null}
              </SubHeader>
              {datePickerOpen ? (
                <DatePickerContainer>
                  <DateRangePicker
                    ranges={[{ startDate, endDate, key: 'selection' }]}
                    onChange={onDatePickerChange as any}
                    maxDate={NOW}
                  />
                  <Button
                    size="small"
                    label="Done"
                    style={{
                      margin: 10,
                      marginLeft: 'auto',
                    }}
                    onClick={closeDatePicker}
                  />
                </DatePickerContainer>
              ) : null}
            </div>
          </ChartHeader>
          {dataMissingError ? (
            <ErrorLabel>
              Some data is missing from the selected date range
            </ErrorLabel>
          ) : null}
          <ModalContent>
            {name === CHANNEL_WIND ? (
              <WindEChart
                data={data}
                name={`${name} mph`}
                channelName={name}
                minMax={{ startDate, endDate }}
                unit={unit}
              />
            ) : (
              <LineChart
                data={data}
                name={`${name} ${unit}`}
                channelName={name}
                minMax={{ startDate, endDate }}
                unit={unit}
              />
              // <LineEChart
              //   data={data}
              //   name={`${name} ${unit}`}
              //   channelName={name}
              //   minMax={{ startDate, endDate }}
              //   unit={unit}
              // />
            )}
          </ModalContent>
        </>
      )}
    </Layout>
  );
};
